import './utils/vh.utils.js';
import './utils/animate-on-view.utils'

import './components/uploadFile.component.js';
import './components/inputs.component.js';
import './components/animations.component.js';
import './components/accordion.component.js';
import './components/header.component.js';
import './components/meta-select.components.js';


import './components/swiper-work.component.js';
import './components/swiper-we-help.component.js';

import './libs/countUp.lib.js';

