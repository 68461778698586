const faqAccordion = document.getElementsByClassName('sectors-section__accordion')[0];

if (faqAccordion) {
  const faqItemsArr = faqAccordion.querySelectorAll('.accordion__item');

  faqItemsArr.forEach((item) => {
    const content = item.querySelector('.accordion__item-content');

    item.addEventListener('click', () => {
      if (!item.classList.contains('active')) {
        openAccordion(item);
      }
    });

    function openAccordion(item) {
      // Удаляем класс active у всех элементов
      faqItemsArr.forEach((item) => item.classList.remove('active'));

      // Добавляем класс active только к выбранному элементу
      item.classList.add('active');

      // Устанавливаем высоту контента
      content.style.maxHeight = `${itemCalcMaxHeight(item, content)}px`;
    }

    function itemCalcMaxHeight(item, content) {
      const children = Array.from(content.children);
      const totalHeight = children.reduce((acc, child) => {
        const childHeight = child.offsetHeight;
        return acc + childHeight;
      }, 0);
      return totalHeight + 100;
    }
  });
}
